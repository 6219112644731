<template>
  <div class="container">
    <div class="card text-center" v-if="isLoadingOrder">
      <div class="card-header">...</div>
      <div class="card-body">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
      </div>
    </div>
    <template v-else>
      <b-overlay :show="showOverlay" rounded="sm">
        <div class="card card-custom example example-compact">
          <div class="card-header">
            <h3 class="card-title">
              {{ $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.SALE_DETAILS") }}
            </h3>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>

          <form class="form">
            <div class="card-body">
              <div class="row px-5">
                <div class="col-12 col-md-6">
                  <h6>
                    {{ $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.ORDER_INFOS") }}
                  </h6>
                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.ORDER_ID")
                      }}:</label
                    >
                    <span class="ml-2">{{ viewOrder.orderNumber }}</span>
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT")
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ viewOrder.price }}
                      {{ getPaymentUnitName(viewOrder) | upperCase }}</span
                    >
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
                      }}:</label
                    >
                    <span class="ml-2">{{ viewOrder.fee }}%</span>
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{ $t("PAGES.SALES_MANAGEMENT.LIST.TOTAL") }}:</label
                    >
                    <span class="ml-2"
                      >{{ getTotal(viewOrder) }}
                      {{ getPaymentUnitName(viewOrder) | upperCase }}</span
                    >
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <h6>
                    {{
                      $t(
                        "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.CUSTOMER_INFO"
                      )
                    }}
                  </h6>
                  <div
                    class="d-inline-flex col-12 align-items-center"
                    v-if="viewOrder.customerName"
                  >
                    <label class="col-form-label font-weight-bold">
                      {{ viewOrder.customerName }}
                    </label>
                  </div>

                  <div class="roe">
                    <div
                      class="d-inline-flex col-12 col-md-6 align-items-center"
                      v-if="viewOrder.customerPhone"
                    >
                      <i class="menu-icon flaticon2-phone text-primary"></i>
                      <label class="col-form-label ml-2">
                        {{ viewOrder.customerPhone }}
                      </label>
                    </div>

                    <div
                      v-if="viewOrder.customerMail"
                      class="d-inline-flex col-12 col-md-6 align-items-center"
                    >
                      <i class="menu-icon flaticon2-email text-primary"></i>
                      <label class="col-form-label ml-2">
                        {{ viewOrder.customerMail }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 d-flex flex-row flex-wrap">
                  <div class="col-6">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t(
                          "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_STATUS"
                        )
                      }}:</label
                    >
                    <span
                      class="badge ml-2"
                      :class="[
                        getOrderBadgeAndLabel(viewOrder.status).itemClass
                      ]"
                    >
                      {{ getOrderBadgeAndLabel(viewOrder.status).itemLabel }}
                    </span>
                  </div>
                  <div class="col-6" v-if="isOrderStatusCompleted">
                    <!-- <div class="col-12 d-flex flex-row align-items-center">
                      <label class="col-form-label font-weight-bold">
                        {{ $t("COMMON.payment") }}
                      </label>
                      <b-form-checkbox
                        v-model="viewOrder.fiat"
                        @change="onChangeFiat"
                        class="text-uppercase ml-3"
                        name="check-button"
                        size="lg"
                        switch
                      >
                        {{
                          viewOrder.fiat ? "FIAT" : $t("PAGES.DASHBOARD.CRYPTO")
                        }}
                      </b-form-checkbox>
                    </div> -->
                  </div>
                </div>
                <!-- <template v-if="showPaymentInfo && paymentInfo">
                  <div class="w-100 top-divider my-2"></div>
                  <div class="col-12 col-md-6 mt-5">
                    <h6>
                      {{ $t("COMPANY.domestic_transfer_infos") }}
                    </h6>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.bank") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.bankName ? paymentInfo.bankName : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_name") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.accountName ? paymentInfo.accountName : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_type") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.accountType ? paymentInfo.accountType : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >IBAN:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.iban ? paymentInfo.iban : "-"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6  mt-5">
                    <h6>
                      {{ $t("COMPANY.overseas_transfer_infos") }}
                    </h6>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.bank") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasBankName
                          ? paymentInfo.overseasBankName
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.buyer") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasBuyerInfo
                          ? paymentInfo.overseasBuyerInfo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.city") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasCityInfo
                          ? paymentInfo.overseasCityInfo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_no") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasAccountNo
                          ? paymentInfo.overseasAccountNo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.swift_code") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasSwiftCode
                          ? paymentInfo.overseasSwiftCode
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >IBAN:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasIbanNo
                          ? paymentInfo.overseasIbanNo
                          : "-"
                      }}</span>
                    </div>
                  </div>
                </template> -->
              </div>
            </div>
          </form>
          <div class="card-footer d-flex">
            <button class="btn btn-primary" @click="back">
              {{ $t("MENU.BACK") }}
            </button>
            <button
              class="btn btn-outline-primary ml-2"
              v-if="isLoadingOrderReceipt"
            >
              <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
            </button>
            <template v-else-if="isFiatAndCompleted">
              <button
                class="btn btn-outline-success ml-2"
                v-if="orderHasReceipt"
                @click="downloadReceipt"
              >
                <i class="fa fa-file-download"></i>
                {{
                  $t(
                    "PAGES.SALES_MANAGEMENT.ORDER_DETAILS.DOWNLOAD_BANK_RECEIPT"
                  )
                }}
              </button>
            </template>
          </div>
        </div>
      </b-overlay>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import {
  getOrderBadgeAndLabel,
  getTotal,
  getPaymentUnitName
} from "@/utils/order-helpers";

export default {
  data() {
    return {
      isLoadingOrder: false,
      showOverlay: false,
      isLoadingOrderReceipt: false,
      orderHasReceipt: false,
      paymentInfo: null
    };
  },
  computed: {
    ...mapGetters({
      viewOrder: "orders/VIEW_ORDER"
    }),
    getOrderId() {
      return this.viewOrder.id || this.viewOrder._id;
    },
    isOrderStatusCompleted() {
      return `${this.viewOrder.status}` === "1";
    },
    isPaymentStatePending() {
      return `${this.viewOrder?.paymentStateId}` === "1";
    },
    isPaymentStateCompleted() {
      return `${this.viewOrder?.paymentStateId}` === "3";
    },
    isFiatAndCompleted() {
      return this.viewOrder?.fiat && this.isPaymentStateCompleted;
    },
    showPaymentInfo() {
      const _paymentState =
        this.isPaymentStateCompleted || this.isPaymentStatePending;
      const _isFiat = this.viewOrder?.fiat || false;
      return this.isOrderStatusCompleted && _isFiat && _paymentState;
    },
    backRouterName() {
      let routerName = "go(-1)";
      if (this.$route.name === "merchant-order-details") {
        routerName = "merchant-branch-report-list";
      }
      if (this.$route.name === "order-details") {
        routerName = "sales-list";
      }
      return routerName;
    }
  },
  mounted() {
    // console.log(this.viewOrder._id)
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      { title: this.$t("ROUTES.SALE_DETAILS") }
    ]);
  },
  created() {
    this.fetchOrderDetails();
  },
  methods: {
    ...mapActions("orders", [
      "GET_ORDER",
      "UPDATE_ORDER_FIAT",
      "GET_ORDER_RECEIPT",
      "DOWNLOAD_ORDER_RECEIPT"
    ]),
    async fetchOrderDetails() {
      this.isLoadingOrder = true;
      await this.GET_ORDER(this.$route.params.orderID);
      this.isLoadingOrder = false;
      this.getOrderReceipt();
      if (this.showPaymentInfo) {
        this.getPaymentInfo();
      }
    },
    back() {
      if (this.backRouterName === "go(-1)") {
        this.$router.go(-1);
        return;
      }
      // this.$router.push({ name: this.backRouterName });
      window.close();
    },
    getOrderBadgeAndLabel: getOrderBadgeAndLabel,
    getTotal: getTotal,
    getPaymentUnitName: getPaymentUnitName,
    async onChangeFiat() {
      if (!this.isOrderStatusCompleted) return;
      const fiat = this.viewOrder.fiat;
      this.showOverlay = true;
      try {
        await this.UPDATE_ORDER_FIAT({ orderId: this.getOrderId, fiat });
        this.showOverlay = false;
      } catch (error) {
        console.log(error);
        this.showOverlay = false;
        const title = this.$t("FORM.ERROR_TITLE");
        const errorMessage = this.$t("FORM.ERROR");
        this.$root.$bvToast.toast(errorMessage, {
          title: title,
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async getOrderReceipt() {
      this.isLoadingOrderReceipt = true;
      try {
        const res = await this.GET_ORDER_RECEIPT(this.getOrderId);
        this.isLoadingOrderReceipt = false;
        this.orderHasReceipt = !!res.data?.data || false;
        return "success";
      } catch (error) {
        this.orderHasReceipt = false;
        this.isLoadingOrderReceipt = false;
      }
    },
    async downloadReceipt() {
      this.showOverlay = true;
      try {
        await this.DOWNLOAD_ORDER_RECEIPT(this.getOrderId);
        this.showOverlay = false;
      } catch (error) {
        console.log("downloadReceipt: ", error);
        this.showOverlay = false;
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async getPaymentInfo() {
      try {
        const res = await this.$store.dispatch(
          "company/GET_PAYMENT_INFO_BY_MERCHANT",
          this.viewOrder.merchantId
        );
        this.paymentInfo = res?.data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.top-divider {
  border-top: 1px solid #bababa;
}
</style>
